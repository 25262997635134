












import InsideContainer from '@/components/Containers/InsideContainer.vue'

export default {
  name: 'About',
  components: {
    InsideContainer,
  },
}
